import { render, staticRenderFns } from "./FirmOrderStatistics.vue?vue&type=template&id=71a05a61&scoped=true&"
import script from "./FirmOrderStatistics.vue?vue&type=script&lang=js&"
export * from "./FirmOrderStatistics.vue?vue&type=script&lang=js&"
import style0 from "./FirmOrderStatistics.vue?vue&type=style&index=0&id=71a05a61&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a05a61",
  null
  
)

export default component.exports